import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import * as rangeSlider from '@elements/range-slider';
rangeSlider.init();

import * as productGrid from '@elements/product-grid';
productGrid.init();

import * as productGridQuickFilter from '@elements/product-grid/quick-filter';
productGridQuickFilter.init();

import * as dropdownPersist from './scripts/dropdown-persist';
dropdownPersist.init();

import * as overLay from './scripts/overlay';
overLay.init();