"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import { hasClass, on,} from '@elements/dom-utils';



export function init () {
    onFind('.js-dropdown-persist', function (dropdown) {
        const dropdownClickHandler = (evt) => {
            if (!hasClass('js-close-dropdown', evt.target) ){
                evt.stopPropagation();
            }
        };
        on('click', (evt) => dropdownClickHandler(evt), dropdown);
    });
}
