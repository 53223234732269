"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, find, findIn, closest, removeAttribute} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

let isOpen;
let currentScrollPosition = 0;

export function init () {
    onFind('.js-overlay__toggle', function (item) {
        on('click', function () {
            let target = find(item.getAttribute('data-target'));

            if (hasClass('is-open', target)) {
                removeClass('is-open', target);

                if (matchMedia('(max-width: 767px)').matches) {
                    deactivateScrollLock();
                }
                isOpen = false;
            } else {
                addClass('is-open', target);
                if (matchMedia('(max-width: 767px)').matches) {
                    activateScrollLock();
                }
                isOpen = target.getAttribute('id');

                window.addEventListener("click", function _listener(evt) {
                    if (!target.contains(evt.target)) {
                        removeClass('is-open', target);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);
            }

        }, item);
    });
}
